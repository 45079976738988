import { Region } from "@/gql/graphql";
import { useTranslation } from "react-i18next";
import { MultiSelectFilter } from "@/lib/Components/DataTable/Filters/MultiSelectFilter";

export function RegionFilter({
  selected,
  onChange,
}: {
  selected: Region[];
  onChange: (newRegions: Region[]) => void;
}) {
  const { t } = useTranslation("city");
  const options = Object.values(Region).map((r) => ({
    label: t(`region.${r}`),
    value: r,
  }));

  return (
    <MultiSelectFilter
      label="Region"
      options={options}
      selected={selected}
      onChange={onChange}
    />
  );
}
