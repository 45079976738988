import { days, OpenHours } from "@/app/Offices/Utils/days";
import dayjs from "dayjs";
import { cn } from "@/lib/utils";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import { Badge } from "@/components/catalyst/badge";

export function OpenHoursViewState({
  openHours,
  parentOpenHours,
}: {
  openHours: OpenHours;
  parentOpenHours?: OpenHours;
}) {
  return (
    <div className="col-span-full grid grid-cols-7 gap-x-2">
      {days.map((day) => {
        const isClosed = openHours[day]?.length === 0;
        const isDifferentFromParent =
          parentOpenHours &&
          JSON.stringify(parentOpenHours[day]) !==
            JSON.stringify(openHours[day]);

        return (
          <div
            key={day}
            className={cn("flex flex-col rounded-md p-1 ", {
              "bg-yellow-400/20": isDifferentFromParent,
              "bg-red-50": isClosed,
            })}
          >
            <div>
              <div className="flex items-center space-x-2   text-xs text-yellow-700">
                <span className="capitalize">{day}</span>
                {isDifferentFromParent ? (
                  <Tooltip>
                    <TooltipTrigger>
                      <ExclamationTriangleIcon className="h-4 w-4 text-yellow-400" />
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>Default hours</p>
                      {parentOpenHours[day]?.map((hours) => {
                        return (
                          <div
                            className="flex flex-col font-semibold"
                            key={hours}
                          >
                            <time>
                              {dayjs(hours.split("-")[0], "HH:mm").format(
                                "h:mma"
                              )}
                            </time>
                            <time>
                              {dayjs(hours.split("-")[1], "HH:mm").format(
                                "h:mma"
                              )}
                            </time>
                          </div>
                        );
                      })}
                    </TooltipContent>
                  </Tooltip>
                ) : null}
              </div>
              <div>
                {isClosed ? (
                  <div className="font-bold text-red-700">CLOSED</div>
                ) : null}
                {openHours[day]?.map((hours) => {
                  return (
                    <div className="flex flex-col font-semibold" key={hours}>
                      <time>
                        {dayjs(hours.split("-")[0], "HH:mm").format("h:mma")}
                      </time>
                      <time>
                        {dayjs(hours.split("-")[1], "HH:mm").format("h:mma")}
                      </time>
                    </div>
                  );
                })}
              </div>
            </div>
            <div></div>
          </div>
        );
      })}
    </div>
  );
}
