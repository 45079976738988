import { JucyIntegrationScreen } from "@/app/Settings/Integrations/Jucy/Screens/JucyIntegrationScreen";
import { useIntegrationRecord } from "@/app/Settings/Settings/Hooks/useIntegrationRecord";
import { IntegrationType } from "@/gql/graphql";
import { CampermanIntegrationScreen } from "@/app/Settings/Integrations/Camperman/CampermanIntegrationScreen";

export function SettingsIntegrationScreen() {
  const integration = useIntegrationRecord();

  if (integration.type === IntegrationType.Jucy) {
    return <JucyIntegrationScreen integration={integration} />;
  }

  if (integration.type === IntegrationType.Camperman) {
    return <CampermanIntegrationScreen />;
  }

  throw new Error(`Integration type ${integration.type} not implemented`);
}
