import { GenericDialog } from "@/lib/Components/Dialog/GenericDialog";
import {
  getQueryKey,
  useGqlMutation,
  useSuspenseGqlQuery,
} from "@/lib/GraphQLCodegen/fetcher";
import { organisationRecordQueryGraphql } from "@/app/Organisations/GraphQL/organisationRecordQuery.graphql";
import { Checkbox } from "@/components/ui/checkbox";
import { FeatureFlagType } from "@/gql/graphql";
import { featureFlagMutation } from "@/app/Meta/GraphQL/featureFlagMutation.grahpql";
import { useQueryClient } from "@tanstack/react-query";

export function FeatureFlagDialog({
  organisationId,
}: {
  organisationId: string;
}) {
  return (
    <GenericDialog title="Feature Flags">
      <div className="space-y-6">
        <FeatureFlagCheckbox
          organisationId={organisationId}
          featureFlagType={FeatureFlagType.VariableRelocationRate}
          label="Variable Relocation Rate"
          description="Allow the organisation to change the relocation rate"
        />

        <FeatureFlagCheckbox
          organisationId={organisationId}
          featureFlagType={FeatureFlagType.InvoiceOverdueBanner}
          label="Invoice banner"
          description="Show overdue invoice banner when an invoice has not been paid"
        />

        <FeatureFlagCheckbox
          organisationId={organisationId}
          featureFlagType={FeatureFlagType.BookingAcceptedState}
          label="Booking Accepted State"
          description="Allow the organisation to put the booking in an accepted state"
        />

        <FeatureFlagCheckbox
          organisationId={organisationId}
          featureFlagType={FeatureFlagType.JucyIntegration}
          label="Jucy Integration"
          description="Enable the Jucy integration"
        />

        <FeatureFlagCheckbox
          organisationId={organisationId}
          featureFlagType={FeatureFlagType.CampermanIntegration}
          label="Camperman Integration"
          description="Enable the Camperman integration"
        />
      </div>
    </GenericDialog>
  );
}

function FeatureFlagCheckbox({
  featureFlagType,
  label,
  description,
  organisationId,
}: {
  featureFlagType: FeatureFlagType;
  label: string;
  description: string;
  organisationId: string;
}) {
  const queryClient = useQueryClient();
  const { data } = useSuspenseGqlQuery(organisationRecordQueryGraphql, {
    id: organisationId,
  });
  const { mutateAsync, isPending } = useGqlMutation(featureFlagMutation, {
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: getQueryKey(organisationRecordQueryGraphql, {
          id: organisationId,
        }),
      });
    },
  });

  const featureFlags = data.organisation.featureFlags;

  const isFlagChecked = !!featureFlags.find(
    (flag) => flag.type === featureFlagType
  );

  return (
    <div className="items-top flex space-x-2">
      <Checkbox
        id={featureFlagType}
        disabled={isPending}
        checked={isFlagChecked}
        onCheckedChange={() => {
          mutateAsync({
            input: {
              organisation_id: organisationId,
              type: featureFlagType,
              enabled: !isFlagChecked,
            },
          });
        }}
      />
      <div className="grid gap-1.5 leading-none">
        <label
          htmlFor={featureFlagType}
          className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
        >
          {label}
        </label>
        <p className="text-muted-foreground text-sm">{description}</p>
      </div>
    </div>
  );
}
