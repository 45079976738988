import {
  QuerySupplierInsuranceOptionsOrderByColumn,
  SortOrder,
  SupplierInsuranceOptionListQueryVariables,
} from "@/gql/graphql";
import { formatCurrency } from "@/lib/Formatters/formatCurrency";
import { BadgeCell } from "@/lib/Components/Tables/Components/BadgeCell";
import {
  SupplierInsuranceOptionListItem,
  supplierInsuranceOptionListQuery,
} from "@/app/Suppliers/GraphQL/supplierInsuranceOptionListQuery";
import { SupplierRecord } from "@/app/Suppliers/GraphQL/supplierRecordQuery";
import { useSupplierInsuranceOptionActions } from "@/app/Suppliers/Hooks/useSupplierInsuranceOptionActions";
import {
  DataTable,
  DataTableColDef,
} from "@/lib/Components/DataTable/DataTable";
import { TableId } from "@/app/Common/Utils/tableIds";
import { ReactNode } from "react";

type SupplierInsuranceOptionTableProps = {
  queryVariables?: Partial<SupplierInsuranceOptionListQueryVariables>;
  supplier: SupplierRecord;
  id: TableId;
  columns?: DataTableColDef<
    SupplierInsuranceOptionListItem,
    unknown,
    SupplierInsuranceTableId
  >[];
  rightButtons?: ReactNode;
};

export type SupplierInsuranceTableId =
  | "name"
  | "badges"
  | "bond"
  | "standard_liability";
export function SupplierInsuranceOptionTable({
  id,
  queryVariables,
  supplier,
  columns,
  rightButtons,
}: SupplierInsuranceOptionTableProps) {
  const getActions = useSupplierInsuranceOptionActions();

  const defaultColumns: DataTableColDef<
    SupplierInsuranceOptionListItem,
    unknown,
    SupplierInsuranceTableId
  >[] = [
    {
      id: "name",
      header: "Name",
      accessorFn: (row) => row.name,
    },
    {
      id: "badges",
      header: "",
      cell: ({ row }) => (
        <BadgeCell
          badges={[
            {
              label: "Primary",
              intent: "primary",
              isHidden: !row.original.is_default,
            },
          ]}
        />
      ),
    },
    {
      id: "bond",
      header: "Bond",
      accessorFn: (row) => formatCurrency(row.bond_amount, supplier.currency),
    },
    {
      id: "standard_liability",
      header: "Standard Liability",
      accessorFn: (row) =>
        formatCurrency(row.standard_liability_amount, supplier.currency),
    },
  ];

  return (
    <DataTable
      id={id}
      getActions={getActions}
      document={supplierInsuranceOptionListQuery}
      accessor={(data) => data.supplierInsuranceOptions}
      getQueryVariables={({ pagination }) => {
        return {
          first: pagination.pageSize,
          page: pagination.pageIndex,
          supplierId: supplier.id,
          orderBy: [
            {
              column: QuerySupplierInsuranceOptionsOrderByColumn.Id,
              order: SortOrder.Asc,
            },
          ],
          ...queryVariables,
        };
      }}
      columns={columns ? [...defaultColumns, ...columns] : defaultColumns}
      rightButtons={rightButtons}
    />
  );
}
