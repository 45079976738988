import { AgGridReact } from "ag-grid-react";
import { RelocationItemInput } from "@/gql/graphql";
import { importRelocationValidation } from "@/app/Import/Utils/importRelocationValidation";
import { ZodError } from "zod";

export function getAndValidateRows(grid: AgGridReact) {
  const rowData: RelocationItemInput[] = [];
  let row = 0;
  try {
    grid.api.forEachNode((node, index) => {
      row = index;
      if (node.data && Object.keys(node.data).length > 0) {
        //Validate the data
        const res = importRelocationValidation.parse(node.data);

        rowData.push(res);
      }
    });
  } catch (e: any) {
    if (e instanceof ZodError) {
      let message = `An error occurred on row ${row + 1}\n\n`;

      e.errors.forEach((err) => {
        message += `${string_path(err.path)}: ${err.message}\n`;
      });

      alert(message);
    }

    return false;
  }

  return rowData;
}

const string_path = (path: any) =>
  path
    .reduce(
      (acc: any, item: any) =>
        acc + (Number.isInteger(item) ? "[" + item + "]" : "." + item),
      ""
    )
    .substring(1);
