import {
  SelectInput,
  SelectInputProps,
} from "@/lib/Components/Form/Inputs/SelectInput";
import { BookingStatus } from "@/gql/graphql";
import { useTenant } from "@/app/Organisations/Hooks/useTenant";
import { useTranslation } from "react-i18next";

export function BookingCancelledStatusInput(
  props: Omit<SelectInputProps<any>, "options"> & {
    enabledStatuses?: BookingStatus[];
  }
) {
  const { isAdmin } = useTenant();
  const { t } = useTranslation("booking");

  const customerOptions: BookingStatus[] = [
    BookingStatus.CustomerCancelledNoShow,
    BookingStatus.CustomerCancelledConfirmationTimeliness,
    BookingStatus.CustomerCancelledRebooked,
    BookingStatus.CustomerCancelled,
  ].filter((status) => {
    return props.enabledStatuses?.includes(status) ?? true;
  });

  const supplierOptions: BookingStatus[] = [
    BookingStatus.SupplierCancelledNoFerry,
    BookingStatus.SupplierCancelledMechanicalFailure,
    BookingStatus.SupplierCancelledNotAvailable,
    BookingStatus.SupplierCancelled,
  ].filter((status) => {
    return props.enabledStatuses?.includes(status) ?? true;
  });

  const adminOptions: BookingStatus[] = [BookingStatus.AdminCancelled];

  return (
    <SelectInput
      {...props}
      options={[
        {
          label: "customer",
          options: customerOptions.map((status) => {
            return {
              value: status,
              label: t(`statusInputLabel.${status}`),
            };
          }),
        },
        {
          label: "supplier",
          options: supplierOptions.map((status) => {
            return {
              value: status,
              label: t(`statusInputLabel.${status}`),
            };
          }),
        },
        ...(isAdmin
          ? [
              {
                label: "admin",
                options: adminOptions.map((status) => {
                  return {
                    value: status,
                    label: t(`statusInputLabel.${status}`),
                  };
                }),
              },
            ]
          : []),
      ]}
    />
  );
}
