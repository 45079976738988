import z from "zod";

export const importRelocationValidation = z.object({
  available_from: z.string(),
  available_to: z.string(),
  delivery_office_code: z.string(),
  departure_office_code: z.string(),
  vehicle_code: z.string(),
  distance_allowed: z.coerce.number().nullish(),
  extra_hire_unit_supplier_net_rate: z.coerce.number().nullish(),
  extra_hire_units_allowed: z.coerce.number().nullish(),
  ferry_note: z.string().nullish(),
  fuel_amount: z.coerce.number().nullish(),
  fuel_note: z.string().nullish(),
  hire_unit_rate: z.coerce.number().nullish(),
  hire_units_allowed: z.coerce.number().nullish(),
  inclusion_note: z.string().nullish(),
  line: z.coerce.string().nullish(),
  preparation_charge_amount: z.coerce.number().nullish(),
  preparation_charge_note: z.string().nullish(),
  quantity: z.coerce.number().nullish(),
});
