import { WarningBanner } from "@/lib/Components/Banners/WarningBanner";
import { SupplierOfficeListItem } from "@/app/Offices/GraphQL/supplierOfficeListQuery";
import { Link } from "@tanstack/react-router";
import { useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/16/solid";
import { VehicleListItem } from "@/app/Vehicles/GraphQL/vehicleListQuery";

export function MissingCodeWarningBanner({
  offices,
  vehicles,
}: {
  offices: SupplierOfficeListItem[];
  vehicles: VehicleListItem[];
}) {
  const [showOffices, setShowOffices] = useState(false);
  const [showVehicles, setShowVehicles] = useState(false);

  const officesWithoutCodes = offices.filter((office) => !office.code);
  const vehiclesWithoutCodes = vehicles.filter((vehicle) => !vehicle.code);

  if (officesWithoutCodes.length === 0 && vehiclesWithoutCodes.length === 0) {
    return null;
  }

  return (
    <WarningBanner>
      <div>
        {officesWithoutCodes.length > 0 ? (
          <>
            {officesWithoutCodes.length} office(s) are missing codes.{" "}
            <button
              onClick={() => {
                setShowOffices(!showOffices);
              }}
              className="inline-flex items-center space-x-2 underline"
            >
              show <ChevronDownIcon className="size-3" />
            </button>
            {showOffices ? (
              <ul className="list-inside list-disc">
                {officesWithoutCodes.map((office) => (
                  <li key={office.id}>
                    <Link className="underline" to={`/offices/${office.id}`}>
                      {office.name}
                    </Link>
                  </li>
                ))}
              </ul>
            ) : null}
          </>
        ) : null}
      </div>

      <div>
        {vehiclesWithoutCodes.length > 0 ? (
          <>
            {vehiclesWithoutCodes.length} vehicle(s) are missing codes.{" "}
            <button
              onClick={() => {
                setShowVehicles(!showVehicles);
              }}
              className="inline-flex items-center space-x-2 underline"
            >
              show <ChevronDownIcon className="size-3" />
            </button>
            {showVehicles ? (
              <ul className="list-inside list-disc">
                {vehiclesWithoutCodes.map((vehicle) => (
                  <li key={vehicle.id}>
                    <Link className="underline" to={`/vehicles/${vehicle.id}`}>
                      {vehicle.name}
                    </Link>
                  </li>
                ))}
              </ul>
            ) : null}
          </>
        ) : null}
      </div>
    </WarningBanner>
  );
}
