import { useSuspenseGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { importSettingRecord } from "@/app/Import/GraphQL/updateImportSettingsMutation";
import { RelocationItemInput } from "@/gql/graphql";
import { GridState } from "ag-grid-community";
import dayjs from "dayjs";

type ImportColumnId = keyof RelocationItemInput;
const defaultOrder: ImportColumnId[] = [
  "quantity",
  "line",
  "vehicle_code",
  "departure_office_code",
  "delivery_office_code",
  "available_from",
  "available_to",
  "hire_units_allowed",
  "extra_hire_units_allowed",
  "fuel_amount",
  "fuel_note",
  "ferry_note",
  "inclusion_note",
  "preparation_charge_amount",
  "preparation_charge_note",
];

export function useGetTransformFileInputToRowData(
  supplierId: string
): (rowData: any[][]) => RelocationItemInput[] {
  const { data: importSettingRes } = useSuspenseGqlQuery(importSettingRecord, {
    supplierId,
  });

  const gridState = importSettingRes?.importSetting?.grid_state as
    | GridState
    | undefined;

  const ignoreColumns = importSettingRes?.importSetting?.ignore_columns ?? "";
  const ignoreColumnsArr = ignoreColumns
    .split(",")
    .map((col) => col.trim())
    .map((col) => {
      //Translate A,B,C to the indexes of the columns
      return lettersToNumber(col) - 1;
    });

  const colOrder =
    (gridState?.columnOrder?.orderedColIds as ImportColumnId[]) ?? defaultOrder;

  //Filter out the columns that are not in the default order
  const filteredColOrder = colOrder.filter((colId) => {
    if (!gridState?.columnVisibility) return true;

    return gridState.columnVisibility?.hiddenColIds.includes(colId) === false;
  });

  return (rawData): RelocationItemInput[] => {
    return rawData
      .filter((row, idx) => {
        //Check if the row contains all null values
        if (row.find((r) => r !== null) === undefined) {
          return false;
        }

        //Ignore the header row if the setting is enabled
        return !(
          importSettingRes?.importSetting?.ignore_header_row && idx === 0
        );
      })
      .map((row) => {
        let skippedColumns = 0;

        const entries: any = row
          .map((cell, cellIdx) => {
            if (ignoreColumnsArr.includes(cellIdx)) {
              skippedColumns++;
              return null;
            }

            //Translate the indexes to the column ids, if we've skipped some columns
            //we need to adjust the index, so we can get the correct column id
            const colId = filteredColOrder[cellIdx - skippedColumns];

            if (colId === undefined) {
              console.log(`There is no column id for the cell index`, {
                columns: filteredColOrder,
                idx: cellIdx - skippedColumns,
                cellIdx,
                skippedColumns,
                filteredColOrder,
              });
              return null;
            }

            if (cell === null) {
              return [colId, null];
            }

            if (
              colId === "available_from" ||
              colId === "available_to" ||
              cell instanceof Date
            ) {
              return [colId, dayjs(cell).format("YYYY-MM-DD")];
            }

            //Stringify the numbers
            if (
              (colId === "line" ||
                colId === "departure_office_code" ||
                colId === "delivery_office_code" ||
                colId === "vehicle_code") &&
              typeof cell === "number"
            ) {
              return [colId, cell.toString()];
            }

            if (
              colId === "fuel_note" &&
              (cell === "0" || cell === "$0" || cell === 0)
            ) {
              //Filter out "0" values
              return [colId, ""];
            }

            return [colId, cell];
          })
          .filter((value) => value !== null);

        return Object.fromEntries(entries) as RelocationItemInput;
      })
      .filter((row) => {
        if (!importSettingRes.importSetting?.ignore_missing_line) {
          return true;
        }

        //Filter out the rows that are missing a line
        return row.line;
      });
  };
}

// convert A to 1, Z to 26, AA to 27
function lettersToNumber(letters: string): number {
  return letters.split("").reduce((r, a) => r * 26 + parseInt(a, 36) - 9, 0);
}
