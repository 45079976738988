import {
  FC,
  MouseEvent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { AgGridReact } from "ag-grid-react";
import { ColDef, SideBarDef } from "ag-grid-community";
import {
  ImportRelocationType,
  QuerySupplierOfficesOrderByColumn,
  QueryVehiclesOrderByColumn,
  RelocationItemInput,
  RelocationStatus,
  SortOrder,
} from "@/gql/graphql";
import { UniqueValueCell } from "@/app/Import/Components/UniqueValueCell";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import {
  ArrowPathIcon,
  ChevronUpIcon,
  SquaresPlusIcon,
} from "@heroicons/react/20/solid";
import { useUpdateImportSettings } from "@/app/Import/Utils/useUpdateImportSettings";
import { VehicleRenderCell } from "@/app/Import/Components/VehicleRenderCell";
import {
  useGqlMutation,
  useSuspenseGqlQuery,
} from "@/lib/GraphQLCodegen/fetcher";
import { supplierOfficeListQuery } from "@/app/Offices/GraphQL/supplierOfficeListQuery";
import { vehicleListQuery } from "@/app/Vehicles/GraphQL/vehicleListQuery";
import { OfficeRenderCell } from "@/app/Import/Components/OfficeRenderCell";
import { supplierRecordQuery } from "@/app/Suppliers/GraphQL/supplierRecordQuery";
import { FutureDateCell } from "@/app/Import/Components/FutureDateCell";
import { CurrencyRenderCell } from "@/app/Import/Components/CurrencyRenderCell";
import { importSettingRecord } from "@/app/Import/GraphQL/updateImportSettingsMutation";
import { useImportState } from "@/app/Import/Stores/useImportState";
import { SettingsToolPanel } from "@/app/Import/Components/SettingsToolPanel";
import { importRelocationsMutation } from "@/app/Import/GraphQL/importMutations";
import { useMatch, useNavigate } from "@tanstack/react-router";
import dayjs from "dayjs";
import { Spinner } from "@/lib/Components/Layout/Loaders/Spinner";
import { MissingCodeWarningBanner } from "@/app/Import/Components/MissingCodeWarningBanner";
import { getAndValidateRows } from "@/app/Import/Utils/getAndValidateRows";

type ImportTableProps = {
  supplierId: string;
};

export function ImportTable({ supplierId }: ImportTableProps) {
  const gridRef = useRef<AgGridReact<RelocationItemInput>>();

  const { data: supplierRes } = useSuspenseGqlQuery(supplierRecordQuery, {
    id: supplierId,
  });
  const { supplier } = supplierRes;

  const { data: importSettingRes } = useSuspenseGqlQuery(importSettingRecord, {
    supplierId,
  });

  const updateGridState = useUpdateImportSettings(supplierId);

  const { data: vehicleRes } = useSuspenseGqlQuery(vehicleListQuery, {
    page: 1,
    first: 200,
    supplier_id: supplierId,
    archived: false,
    orderBy: [
      {
        order: SortOrder.Asc,
        column: QueryVehiclesOrderByColumn.Code,
      },
    ],
  });

  const vehicles = vehicleRes.vehicles.data;

  const { data: officesRes } = useSuspenseGqlQuery(supplierOfficeListQuery, {
    page: 1,
    first: 200,
    supplier_id: supplierId,
    archived: false,
    orderBy: [
      {
        order: SortOrder.Asc,
        column: QuerySupplierOfficesOrderByColumn.Code,
      },
    ],
  });

  const offices = officesRes.supplierOffices.data;

  const vehicleCodes = vehicles.map((vehicle) => vehicle.code);
  const officeCodes = offices.map((office) => office.code);

  // Each Column Definition results in one Column.
  const columnDefs = useMemo<ColDef<RelocationItemInput>[]>(
    () => [
      {
        field: "quantity",
        cellDataType: "number",
        hide: true,
      },
      { field: "line", headerName: "Rego/line", cellRenderer: UniqueValueCell },
      {
        field: "vehicle_code",
        cellEditor: "agRichSelectCellEditor",
        cellRenderer: VehicleRenderCell,
        headerName: "Vehicle",
        cellRendererParams: {
          supplier,
        },
        cellEditorParams: {
          values: vehicleCodes,
          cellHeight: 20,
        },
      },
      {
        field: "departure_office_code",
        headerName: "Origin",
        cellEditor: "agRichSelectCellEditor",
        cellEditorParams: {
          values: officeCodes,
          cellHeight: 20,
        },
        cellRenderer: OfficeRenderCell,
        cellRendererParams: {
          supplier,
          officeType: "departure",
        },
      },
      {
        field: "delivery_office_code",
        headerName: "Destination",
        cellEditor: "agRichSelectCellEditor",
        cellEditorParams: {
          values: officeCodes,
          cellHeight: 20,
        },
        cellRenderer: OfficeRenderCell,
        cellRendererParams: {
          supplier: supplier,
          officeType: "delivery",
        },
      },
      {
        field: "available_from",
        cellDataType: "dateString",
        cellRenderer: FutureDateCell,
        headerName: "Available from",
      },
      {
        field: "available_to",
        cellDataType: "dateString",
        headerName: "Available to",
        cellRenderer: FutureDateCell,
      },
      {
        field: "hire_units_allowed",
        cellDataType: "number",
        headerName: "Relocation days",
      },
      {
        field: "extra_hire_units_allowed",
        cellDataType: "number",
        headerName: "Extra days",
      },
      {
        field: "fuel_amount",
        cellDataType: "number",
        headerName: "Fuel amount",
        cellRenderer: CurrencyRenderCell,
        cellRendererParams: {
          currency: supplier.currency,
        },
      },
      { field: "fuel_note", headerName: "Fuel note", hide: true },
      { field: "ferry_note", headerName: "Ferry note", hide: true },
      { field: "inclusion_note", headerName: "Other Inclusions", hide: true },

      { field: "distance_allowed", headerName: "Mileage", hide: true },
      { field: "hire_unit_rate", headerName: "Relocation rate", hide: true },
      {
        field: "extra_hire_unit_supplier_net_rate",
        headerName: "Extra day rate",
        hide: true,
      },
      {
        field: "preparation_charge_amount",
        headerName: "Preparation charge",
        hide: true,
      },
      {
        field: "preparation_charge_note",
        headerName: "Preparation charge note",
        hide: true,
      },
    ],
    []
  );

  const { relocations, reset } = useImportState();
  const rowData = useMemo<RelocationItemInput[]>(() => {
    return relocations;
  }, []);

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  const defaultColDef: ColDef<any> = useMemo(
    () => ({
      editable: true,
      sortable: false,
      resizable: true,
      enableCellChangeFlash: true,
      enablePivot: false,
      rowGroup: false,
    }),
    []
  );

  const sideBar = useMemo<SideBarDef>(() => {
    return {
      hiddenByDefault: false,
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
            suppressPivotMode: true,
          },
        },
        {
          id: "settings",
          labelDefault: "Settings",
          labelKey: "settings",
          iconKey: "settings",
          toolPanel: SettingsToolPanel,
          toolPanelParams: {
            supplierId,
          },
        },
      ],
    } satisfies SideBarDef;
  }, []);

  const getSelectedRows = useCallback(() => {
    const range = gridRef.current?.api.getCellRanges();
    const rowStart = range?.at(0)?.startRow?.rowIndex;
    const rowEnd = range?.at(0)?.endRow?.rowIndex;

    if (rowStart === undefined || rowStart === null) return;
    if (rowEnd === undefined || rowEnd === null) return;

    const start = rowStart < rowEnd ? rowStart : rowEnd;
    const end = rowStart < rowEnd ? rowEnd : rowStart;

    return Array.from({ length: end - start + 1 }, (_, i) => i + start!)
      .map((rowIndex) => {
        return gridRef.current?.api.getDisplayedRowAtIndex(rowIndex)?.data;
      })
      .filter(Boolean);
  }, []);

  const deleteSelected = useCallback(() => {
    const rows = getSelectedRows();
    gridRef.current?.api.applyTransaction({
      remove: rows,
    });
  }, [gridRef]);

  const getContextMenuItems = useCallback(() => {
    return [
      "copy",
      "paste",
      {
        name: "Add row",
        icon: '<span class="ag-icon ag-icon-plus" unselectable="on" role="presentation"></span>',
        action: () => {
          gridRef.current?.api.applyTransaction({
            add: [{} as any],
          });
        },
      },
      {
        name: "Delete row(s)",
        icon: '<span class="ag-icon ag-icon-not-allowed" unselectable="on" role="presentation"></span>',
        action: deleteSelected,
      },
    ];
  }, []);

  const onUpdate = useCallback((e: any) => {
    updateGridState({
      ...e.api.getState(),
      //Set the sidebar state so we're able to default it to being closed
      sideBar: {
        visible: true,
        position: "right",
        toolPanels: { columns: { expandedGroupIds: [] } },
        openToolPanel: null,
      },
    });
  }, []);

  return (
    <div className="ag-theme-quartz relative flex h-full w-full flex-col">
      <div className="absolute bottom-6 right-12 z-10">
        <SplitButton grid={gridRef.current!} />
      </div>

      <MissingCodeWarningBanner offices={offices} vehicles={vehicles} />

      <AgGridReact<RelocationItemInput>
        /**
         *  AG-Grid does not update when the reference  processCellFromClipboard reference is changed (it is internally memoized)
         * To force the grid to update, we need to change the key of the grid component
         **/
        key={importSettingRes.importSetting?.date_format}
        className="flex-grow"
        ref={gridRef as any}
        rowData={rowData}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        gridOptions={{
          cellSelection: true,
          initialState: importSettingRes.importSetting?.grid_state,
          processCellFromClipboard: (params) => {
            if (
              params.column.getColId() === "available_from" ||
              params.column.getColId() === "available_to"
            ) {
              return dayjs(
                params.value,
                importSettingRes.importSetting?.date_format
              ).format("YYYY-MM-DD");
            }

            if (
              params.column.getColId() === "fuel_amount" ||
              params.column.getColId() === "charge_amount" ||
              params.column.getColId() === "hire_units_allowed" ||
              params.column.getColId() === "extra_hire_units_allowed"
            ) {
              if (params.value === "") {
                return null;
              }

              return parseFloat(params.value);
            }

            return params.value;
          },
        }}
        animateRows={true}
        getContextMenuItems={getContextMenuItems}
        onColumnMoved={onUpdate}
        onColumnResized={onUpdate}
        onColumnValueChanged={onUpdate}
        onColumnVisible={onUpdate}
        onColumnPinned={onUpdate}
        onColumnRowGroupChanged={onUpdate}
        onColumnPivotChanged={onUpdate}
        sideBar={sideBar}
      />
    </div>
  );
}

function SplitButton({ grid }: { grid: AgGridReact<RelocationItemInput> }) {
  const navigate = useNavigate();
  const { mutateAsync: importRelocations } = useGqlMutation(
    importRelocationsMutation
  );
  const { params } = useMatch({
    from: "/_app/import/$supplierId",
  });

  return (
    <div className="inline-flex rounded-md shadow-sm">
      <Menu as="div" className="relative -ml-px block">
        <MenuButton className="relative inline-flex items-center rounded-md bg-black p-2 px-4 text-white ring-1 ring-inset ring-gray-300 hover:bg-gray-700 focus:z-10">
          Upload
          <ChevronUpIcon aria-hidden="true" className="ml-2 h-5 w-5" />
        </MenuButton>
        <MenuItems
          anchor={{
            gap: 4,
            to: "bottom end",
          }}
          className="w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
        >
          <div className="py-1">
            <LoadingMenuItem
              label="Upload items (append)"
              Icon={SquaresPlusIcon}
              onClick={async () => {
                const rows = getAndValidateRows(grid);

                if (rows === false) {
                  return;
                }

                await importRelocations({
                  input: {
                    type: ImportRelocationType.Append,
                    supplierId: params.supplierId,
                    relocations: rows,
                  },
                });

                await navigate({
                  to: "/suppliers/$id/relocations",
                  params: {
                    id: params.supplierId,
                  },
                  search: {
                    relocationStatus: [RelocationStatus.Ready],
                  },
                });
              }}
            />

            <LoadingMenuItem
              label="Upload items (replace)"
              Icon={ArrowPathIcon}
              onClick={async () => {
                const rows = getAndValidateRows(grid);

                if (rows === false) {
                  return;
                }

                await importRelocations({
                  input: {
                    type: ImportRelocationType.New,
                    supplierId: params.supplierId,
                    relocations: rows,
                  },
                });

                await navigate({
                  to: "/suppliers/$id/relocations",
                  params: {
                    id: params.supplierId,
                  },
                  search: {
                    relocationStatus: [RelocationStatus.Ready],
                  },
                });
              }}
            />
          </div>
        </MenuItems>
      </Menu>
    </div>
  );
}

function LoadingMenuItem({
  onClick,
  label,
  Icon,
}: {
  onClick: (e: MouseEvent<HTMLButtonElement, any>) => Promise<void>;
  label: ReactNode;
  Icon: FC<any>;
}) {
  const [loading, setLoading] = useState(false);

  return (
    <MenuItem>
      {({ close }) => (
        <button
          className="block w-full px-4 py-2 text-left text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
          onClick={async (e) => {
            e.preventDefault();
            setLoading(true);

            try {
              await onClick(e);
              close();
            } finally {
              setLoading(false);
            }
          }}
        >
          {!loading ? (
            <Icon aria-hidden="true" className="mr-2 inline h-5 w-5" />
          ) : (
            <Spinner className="mr-2 inline h-5 w-5" />
          )}
          {label}
        </button>
      )}
    </MenuItem>
  );
}
