import { FC, ReactNode } from "react";
import { CalendarDaysIcon } from "@heroicons/react/24/outline";
import {
  CalendarCheckIcon,
  ClipboardCheckIcon,
  ClipboardXIcon,
  TimerOffIcon,
} from "lucide-react";
import { RecordScreenCard } from "@/lib/Components/RecordScreen/Cards/RecordScreenCard";
import {
  FeatureFlagType,
  SupplierNotificationSettingEvent,
  SupplierNotificationSettingType,
} from "@/gql/graphql";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { SupplierNotificationSettingDialog } from "@/app/Suppliers/Components/SupplierNotificationSettingDialog";
import { BadgeGroup } from "@/lib/Components/Badges/BadgeGroup";
import { SupplierRecord } from "@/app/Suppliers/GraphQL/supplierRecordQuery";
import { useFeatureFlag } from "@/app/Meta/utils/useFeatureFlag";

export function SupplierNotificationSettingEventButtons({
  supplier,
  title = "Notifications",
  className,
}: {
  supplier: SupplierRecord;
  className?: string;
  title?: ReactNode;
}) {
  const acceptedEnabled = useFeatureFlag(FeatureFlagType.BookingAcceptedState);

  const buttons = [
    {
      label: "Booking pending",
      Icon: CalendarDaysIcon,
      event: SupplierNotificationSettingEvent.BookingPending,
    },
    {
      label: "Booking confirmed",
      Icon: CalendarCheckIcon,
      event: SupplierNotificationSettingEvent.BookingConfirmed,
    },
    {
      label: "Booking cancelled",
      Icon: ClipboardXIcon,
      event: SupplierNotificationSettingEvent.BookingCancelledAfterConfirmation,
    },
    {
      label: "Pending booking cancelled",
      Icon: ClipboardXIcon,
      event:
        SupplierNotificationSettingEvent.BookingCancelledBeforeConfirmation,
    },
    {
      label: "Relocation expired",
      Icon: TimerOffIcon,
      event: SupplierNotificationSettingEvent.RelocationsExpired,
    },
    ...(!acceptedEnabled
      ? [
          {
            label: "Booking accepted",
            Icon: ClipboardCheckIcon,
            event: SupplierNotificationSettingEvent.BookingAccepted,
          },
        ]
      : []),
  ];

  return (
    <RecordScreenCard title={title} className={className}>
      <div className="grid grid-cols-4 gap-6">
        {buttons.map((button, idx) => (
          <EventButton key={idx} supplier={supplier} {...button} />
        ))}
      </div>
    </RecordScreenCard>
  );
}

type EventButtonProps = {
  label: ReactNode;
  Icon: FC<any>;
  event: SupplierNotificationSettingEvent;
  supplier: SupplierRecord;
};
function EventButton({ label, Icon, event, supplier }: EventButtonProps) {
  const { open } = useDialog(SupplierNotificationSettingDialog);

  const toCount =
    supplier.notificationSettings?.filter(
      (setting) =>
        setting.event === event &&
        setting.type === SupplierNotificationSettingType.To
    ).length ?? 0;

  const ccCount =
    supplier?.notificationSettings?.filter(
      (setting) =>
        setting.event === event &&
        setting.type === SupplierNotificationSettingType.Cc
    ).length ?? 0;

  const bccCount =
    supplier?.notificationSettings?.filter(
      (setting) =>
        setting.event === event &&
        setting.type === SupplierNotificationSettingType.Bcc
    ).length ?? 0;

  return (
    <button
      className="flex aspect-square items-center justify-center rounded-md border border-gray-200 bg-white px-4 py-4 text-gray-700 shadow-lg transition-colors hover:bg-gray-100"
      onClick={() => {
        open({
          supplier,
          event,
        });
      }}
    >
      <div className="flex flex-col items-center space-y-3">
        <Icon className="h-10 w-10" />
        <span className="text-xs text-gray-500">{label}</span>
        <BadgeGroup
          badges={[
            {
              label: `${toCount} recipients`,
              intent: "primary",
              isHidden: toCount === 0,
            },
            {
              label: `${ccCount} cc`,
              intent: "neutral",
              isHidden: ccCount === 0,
            },
            {
              label: `${bccCount} bcc`,
              intent: "neutral",
              isHidden: bccCount === 0,
            },
          ]}
        />
      </div>
    </button>
  );
}
